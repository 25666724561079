// Bundle theme module
import NSTheme from './NSTheme';
import FocusHandler from './Classes/FocusHandler';
import LazyLoad from './Classes/Lazyload';
import objectFitVideos from '../../node_modules/object-fit-videos';
import OpenOverlay from "./Classes/OpenOverlay";

module.exports = NSTheme;

(function () {
  document.addEventListener('DOMContentLoaded', () => {
    setTimeout(function () {
      document.querySelector('body').classList.add('loaded');
    }, 2000);

    new objectFitVideos();

    new FocusHandler();

    new OpenOverlay({
      toggleSelector: '.content__button',
      lightbox: '.lightbox'
    });

    new LazyLoad();
  })
})();
