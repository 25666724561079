import Helper from './Helper';

class OpenOverlay {
  constructor(config = {}) {
    const {
      toggleSelector,
      lightbox
    } = config;

    this.lightboxBackdrop = document.querySelector('.lightbox__backdrop');
    this.lightboxClose = document.querySelector('.lightbox__close-btn');
    this.toggle = document.querySelector(toggleSelector);
    this.lightbox = document.querySelector(lightbox);

    if(this.toggle){
      this.bindEvents();
    }
  }

  bindEvents() {
    this.toggle.addEventListener('click', e => {
      this.openLightbox();
    });

    this.lightboxBackdrop.addEventListener('click', e => {
      this.closeLightbox();
    });

    this.lightboxClose.addEventListener('click', e => {
      this.closeLightbox();
    });

    document.addEventListener('keyup', e => {
      if (e.keyCode == 27) {
        this.closeLightbox();
      }
    });
  }

  openLightbox() {
    Helper.addClass(document.querySelector('body'), 'lightbox-open');
    Helper.addClass(this.lightbox, 'lightbox--is-visible');
  }

  closeLightbox() {
    Helper.removeClass(document.querySelector('body'), 'lightbox-open');
    Helper.removeClass(this.lightbox, 'lightbox--is-visible');
  }
}

export default OpenOverlay;
